function createSimpleElemnts(list, wrapper, classN) {

    let newUl = document.createElement("ul");
    newUl.className = "row row--24";


    let time = 0;
    let products = list;
    for (let i = 0; i < products.length; i++) {

        let product = products[i];
        time += 80;

        let elLi = document.createElement("li");
        elLi.className = classN;
        newUl.appendChild(elLi);


        let elA = document.createElement("a");
        elA.className = `product__link product--${product.kategoriaKolor}`;
        elA.href = product.url;
        elLi.appendChild(elA);

        if (product.nowosc == "1") {
            let elN = document.createElement("span");
            elN.className = "product__new";
            elN.textContent = "Nowość"
            elA.appendChild(elN);
        }
        if (product.ostatnie == "1") {
            let elImageL = document.createElement("img");
            elImageL.className = "product__last";
            elImageL.src = "/szablony/public/img/ico/ico-ostatnie-miejsca.svg";
            elImageL.alt = "Ostatnie miejsca";
            elA.appendChild(elImageL);
        }
        if (product.brak_miejsc == "1") {
            let elImageL = document.createElement("img");
            elImageL.className = "product__last";
            elImageL.src = "/szablony/public/img/ico/brak-miejsc.svg";
            elImageL.alt = "Brak miejsc";
            elA.appendChild(elImageL);
        }


        let newSwiper = document.createElement("div");

        newSwiper.className = "product__swiper swiper mySwiper";

        let newSwiperWrapper = document.createElement("div");
        newSwiperWrapper.className = "swiper-wrapper";

        newSwiperWrapper.innerHTML = product.zdjecia;

        let sbNext = document.createElement("div");
        let sbPrev = document.createElement("div");

        sbNext.className = "swiper-button-next";
        sbPrev.className = "swiper-button-prev";

        newSwiper.appendChild(sbPrev);
        newSwiper.appendChild(sbNext);

        newSwiper.prepend(sbNext);
        newSwiper.prepend(sbPrev);
        newSwiper.prepend(newSwiperWrapper);
        elA.appendChild(newSwiper);


        let elPrB = document.createElement("div");
        elPrB.className = "product__bottom";
        elA.appendChild(elPrB);

        let elPrT = document.createElement("div");
        elPrT.className = "product__title";
        elPrB.appendChild(elPrT);


        if (product.kategoriaUrl) {
            let elImage = document.createElement("img");
            elImage.src = "/szablony/public/img/ico/ico-" + product.kategoriaUrl + ".svg";
            elImage.alt = "kategoria: " + product.kategoriaUrl;
            elPrT.appendChild(elImage);
        }
        if (product.nazwa) {
            let elh3 = document.createElement("h3");
            elh3.textContent = product.nazwa;
            elPrT.appendChild(elh3);
        }

        let elPrI = document.createElement("div");
        elPrI.className = "product__info";
        elPrB.appendChild(elPrI);

        if (product.dni) {
            let elS1 = document.createElement("span");
            elS1.className = "ico ico--time hero__time";
            elS1.textContent = product.dni;
            elprI.appendChild(elS1);
        }

        if (product.kraje) {
            let elS1 = document.createElement("span");
            elS1.className = "ico ico--destination";
            elS1.textContent = product.kraje;
            elPrI.appendChild(elS1);
        }

        if (product.termin) {
            let elS1 = document.createElement("div");
            let elS1Name = document.createElement("span");
            elS1.className = "ico ico--date";
            elS1Name.textContent = product.termin;

            if (product.terminyHtml) {
                elS1.innerHTML = product.terminyHtml
            }
            elS1.prepend(elS1Name);
            elPrI.appendChild(elS1);
        }

        const swiper = new Swiper(newSwiper, {
            lazy: true,
            loop: true,

            navigation: {
                nextEl: sbNext,
                prevEl: sbPrev,
            }
        });
        sbPrev.addEventListener("click", function (e) {
            e.stopPropagation();
        });
        sbNext.addEventListener("click", function (e) {
            e.stopPropagation();
        })


        setTimeout(function () {
            elLi.classList.remove("product__item--op0");
        }, time);
    }

    wrapper.prepend(newUl);

}


//dodawanie produktów do tab-strona główna


function createTabElements(data, el) {

    let id = el.getAttribute("aria-controls");

    let wrapper = document.getElementById(id);

    let products = JSON.parse(data);

    if (products.length > 0) {
        if (wrapper.querySelector(".alert")) {
            wrapper.querySelector(".alert").remove();
        }

        createSimpleElemnts(products, wrapper, "col-md-6 product__item product__item--op0");

    } else {
        if (wrapper.querySelector(".btn-all-cat")) {
            wrapper.querySelector(".btn-all-cat").remove();
        }
        let noEl = document.createElement("div");
        noEl.className = "alert alert-grey";
        noEl.textContent = "W wybranej przez Ciebie kategorii nie ma jeszcze wycieczek";
        wrapper.prepend(noEl);
    }


}


if (document.querySelectorAll(".tabs__nav-trigger")) {
    let tabBtns = document.querySelectorAll("#nasza-oferta .tabs__nav-trigger");
    for (var i = 0; i < tabBtns.length; i++) {
        tabBtns[i].addEventListener("click", function (e) {
            if (!this.classList.contains("js--add")) {
                let el = this;
                let category = this.getAttribute("data-category");

                let token = document.querySelector("meta[name='csrf-token']").getAttribute("content");
                dataToSend = new FormData();
                dataToSend.set('kategoria', category);
                dataToSend.set('__csrf__token__', token);
                makeRequest('/a/?a=ofertyStronaGlowna&kategoria=' + category, "GET", dataToSend, createTabElements, el);
                this.classList.add("js--add");
            }
        });

        if (!tabBtns[0].classList.contains("js--add")) {
            tabBtns[0].click();
        }
    }
}


